@import '../../../module.variables.scss';
.affiliated-agent-create-page {
    .affiliated-agent-create-page__content {
        background: #0f192f;
        border: 1px solid rgba(70, 128, 255, 0.25);
        border-radius: 5px;
        padding: 32px 48px;
        .title {
            font-size: 24px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 32px;
            color: #fff;
        }
        .form {
            .sub-title {
                margin-bottom: 16px;
                color: #fff;
                font-size: 20px;
                font-weight: 600;
                margin-top: 20px;
            }
            .input-container {
                .label {
                    font-size: 14px;
                    color: #8f9bb3;
                    margin-bottom: 8px;
                }
                #thumnail {
                    .input {
                        transform: scale(0.75);
                        -webkit-transform: scale(0.75);
                        -moz-transform: scale(0.75);
                        -ms-transform: scale(0.75);
                        -o-transform: scale(0.75);
                        transform-origin: bottom left;
                    }
                }
            }
            .Table {
                .action {
                    &.inactive {
                        opacity: 0.25;
                    }
                    &:not(.inactive) {
                        .action--edit {
                            cursor: pointer;
                            &:hover {
                                filter: drop-shadow(0 0 8px #7759de);
                                -webkit-filter: drop-shadow(0 0 8px #7759de);
                                svg {
                                    transition: 0.5s;
                                    -webkit-transition: 0.5s;
                                    -moz-transition: 0.5s;
                                    -ms-transition: 0.5s;
                                    -o-transition: 0.5s;
                                    transform: scale(1.25);
                                    -webkit-transform: scale(1.25);
                                    -moz-transform: scale(1.25);
                                    -ms-transform: scale(1.25);
                                    -o-transform: scale(1.25);
                                }
                            }
                        }
                        .action--delete {
                            cursor: pointer;
                            &:hover {
                                filter: drop-shadow(0 0 8px #0466c8);
                                -webkit-filter: drop-shadow(0 0 8px #0466c8);
                                svg {
                                    transition: 0.5s;
                                    -webkit-transition: 0.5s;
                                    -moz-transition: 0.5s;
                                    -ms-transition: 0.5s;
                                    -o-transition: 0.5s;
                                    transform: scale(1.25);
                                    -webkit-transform: scale(1.25);
                                    -moz-transform: scale(1.25);
                                    -ms-transform: scale(1.25);
                                    -o-transform: scale(1.25);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .affiliated-agent-create-page__cover {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    
}