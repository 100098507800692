@import "../../../../module.variables.scss";
.page-user-kyc-list {
    .Table {
        .Table__Body {
            .email {
                color: #4680ff;
            }
            .status {
                font-size: 14px;
                padding: 5px 8px;
                border-radius: 24px;
                color: #ddd;
                background: rgba(221, 221, 221, 0.15);
                border: 1px solid rgba(221, 221, 221, 0.25);
                &.status--pending {
                    color: #fbc217;
                    background: rgba(255, 157, 0, 0.15);
                    border: 1px solid rgba(255, 157, 0, 0.25);
                    // border: 1px solid #fbc217;
                }
                &.status--approved {
                    color: #56ca00;
                    background: rgba(86, 202, 0, 0.15);
                    border: 1px solid rgba(86, 202, 0, 0.25);
                    // border: 1px solid #56ca00;
                }
                &.status--rejected {
                    color: #ff4c51;
                    background: rgba(255, 76, 81, 0.15);
                    border: 1px solid rgba(255, 76, 81, 0.25);
                    // border: 1px solid #ff4c51;
                }
            }
        }
    }
    .input-select-filter-status {
        min-width: 14rem;
    }
}
.PopupWraper {
    .box {
        min-width: 400px;
        .group {
            margin-bottom: 1rem;
        }
        input {
            width: 100%;
            padding: 12px 1rem;
            border-radius: $borderRadius;
            background-color: $secondary-background-color;
            color: white;
            border: unset;
        }
        .label {
            font-size: 1rem;
            color: white;
            // margin-bottom: 1rem;
        }
        .list-image {
            display: flex;
            gap: 4rem;
            .image-item {
                min-width: 200px;
                .label {
                    text-align: center;
                }
                .image-wrapper {
                    height: 100px;
                    width: 100%;
                    margin-top: 2rem;
                    img {
                        cursor: pointer;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
        .btn-action-wrapper {
            display: flex;
            justify-content: center;
            gap: 2rem;
            margin-top: 4rem;
            Button {
                .label {
                    color: black;
                }
            }
        }
    }
}
