.config {
  &--title {
    font-size: 1.5em;
    display: block;
    font-weight: lighter;
  }
  &--item {
    box-shadow: 0 0 10px 8px #13203c;
    border-radius: 8px;
    padding: 24px 12px 20px 12px;
  }
}
