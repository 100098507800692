@import "../../../module.variables.scss";
.page-user-kyc-list {
  .Table {
    .Table__Body {
      .email {
        color: #4680ff;
      }
      .status {
        font-size: 14px;
        padding: 5px 8px;
        border-radius: 24px;
        color: #ddd;
        background: rgba(221, 221, 221, 0.15);
        border: 1px solid rgba(221, 221, 221, 0.25);
        &.status--pending {
          color: #fbc217;
          background: rgba(255, 157, 0, 0.15);
          border: 1px solid rgba(255, 157, 0, 0.25);
          // border: 1px solid #fbc217;
        }
        &.status--approved {
          color: #56ca00;
          background: rgba(86, 202, 0, 0.15);
          border: 1px solid rgba(86, 202, 0, 0.25);
          // border: 1px solid #56ca00;
        }
        &.status--rejected {
          color: #ff4c51;
          background: rgba(255, 76, 81, 0.15);
          border: 1px solid rgba(255, 76, 81, 0.25);
          // border: 1px solid #ff4c51;
        }
      }
    }
  }
}
