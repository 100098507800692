.StartupManagement {
  .actions {
    display: flex;
    .action__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 4px;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
        fill: rgb(119, 89, 222);

        path {
          fill: rgb(119, 89, 222);
        }
      }

      &.action__icon--check {
        svg {
          height: 30px;
        }
      }

      &:hover {
        transform: scale(1.2);
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
      }
    }
  }

  .Table {
    .Table__Body {
      .status {
        &.status--just_created {
          color: #fff;
          background: #01a3ff;
        }
        &.status--approved {
          background-color: #1eba62;
          color: #fff;
        }
        &.status--pending {
          color: #fff;
          background: #a7f55e;
        }
        &.status--cancel {
          color: #fff;
          background: #f15213;
        }
        &.status--activated {
          color: #56ca00;
          background: rgba(86, 202, 0, 0.05);
        }
        &.status--deactivated {
          color: #ff4c51;
          background: rgba(255, 76, 81, 0.05);
        }
        &.status--not_showed {
          color: #fbc217;
          background: rgba(255, 157, 0, 0.05);
        }
      }
    }
  }

  .PopupWraper .box .content {
    min-width: 320px;
    text-align: center;
  }
}
