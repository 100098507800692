@import "../../module.variables.scss";
@import "./components";
.affiliated-agent-page {
    .button-container {
        display: flex;
        justify-content: space-between;
    }
    table {
        tbody {
            .representative-user {
                color: #4680FF;
            }
            .supplier {
                display: flex;
                align-items: center;
                &.inactive {
                    opacity: 0.25;
                    .supplier__image {
                        cursor: default;
                    }
                }
                .supplier__image {
                    margin-right: 16px;
                    width: 127px;
                    height: 54px;
                    object-fit: fill;
                    // border-radius: 50%;
                    // -webkit-border-radius: 50%;
                    // -moz-border-radius: 50%;
                    // -ms-border-radius: 50%;
                    // -o-border-radius: 50%;
                    // border: 1px solid;
                    background: #f6f6f6;
                    cursor: pointer;
                }
            }
            .supplier-code {
                &.inactive {
                    opacity: 0.25;
                }
            }
            .created-date {
                &.inactive {
                    opacity: 0.25;
                }
            }
            .status {
                font-size: 14px;
                padding: 5px 8px;
                border-radius: 24px;
                color: #ddd;
                background: rgba(221, 221, 221, 0.15);
                border: 1px solid rgba(221, 221, 221, 0.25);
                &.status--warning {
                    color: #fbc217;
                    background: rgba(255, 157, 0, 0.15);
                    border: 1px solid rgba(255, 157, 0, 0.25);
                    // border: 1px solid #fbc217;
                }
                &.status--active {
                    color: #56ca00;
                    background: rgba(86, 202, 0, 0.15);
                    border: 1px solid rgba(86, 202, 0, 0.25);
                    // border: 1px solid #56ca00;
                }
                &.status--lock {
                    color: #ff4c51;
                    background: rgba(255, 76, 81, 0.15);
                    border: 1px solid rgba(255, 76, 81, 0.25);
                    // border: 1px solid #ff4c51;
                }
            }
            .active-status {
                padding: 4px 8px;
                border-radius: 24px;
                background: rgba(221, 221, 221, 0.15);
                border: 1px solid rgba(221, 221, 221, 0.25);
                white-space: nowrap;
                &.active-status--activated {
                    background: rgba(#1EBA62, 0.2);
                    color: #1EBA62;
                    border: 1px solid rgba(#1EBA62, 0.25);
                }
                &.active-status--deactivated {
                    background: rgba(#fd5353, 0.2);
                    color: #fd5353;
                    border: 1px solid rgba(#fd5353, 0.25);
                }
                &.active-status--not-showing {
                    background: rgba(221, 221, 221, 0.15);
                    color: #fff;
                    border: 1px solid rgba(221, 221, 221, 0.25);
                }
            }
            .action {
                &.inactive {
                    opacity: 0.25;
                }
                &:not(.inactive) {
                    .action--edit {
                        cursor: pointer;
                        &:hover {
                            filter: drop-shadow(0 0 8px #7759de);
                            -webkit-filter: drop-shadow(0 0 8px #7759de);
                            svg {
                                transition: 0.5s;
                                -webkit-transition: 0.5s;
                                -moz-transition: 0.5s;
                                -ms-transition: 0.5s;
                                -o-transition: 0.5s;
                                transform: scale(1.25);
                                -webkit-transform: scale(1.25);
                                -moz-transform: scale(1.25);
                                -ms-transform: scale(1.25);
                                -o-transform: scale(1.25);
                            }
                        }
                    }
                    .action--change-status {
                        cursor: pointer;
                        &:hover {
                            filter: drop-shadow(0 0 8px #4680ff);
                            -webkit-filter: drop-shadow(0 0 8px #4680ff);
                            svg {
                                transition: 0.5s;
                                -webkit-transition: 0.5s;
                                -moz-transition: 0.5s;
                                -ms-transition: 0.5s;
                                -o-transition: 0.5s;
                                transform: scale(1.25);
                                -webkit-transform: scale(1.25);
                                -moz-transform: scale(1.25);
                                -ms-transform: scale(1.25);
                                -o-transform: scale(1.25);
                            }
                        }
                    }
                    .action--delete {
                        cursor: pointer;
                        &:hover {
                            filter: drop-shadow(0 0 8px #0466c8);
                            -webkit-filter: drop-shadow(0 0 8px #0466c8);
                            svg {
                                transition: 0.5s;
                                -webkit-transition: 0.5s;
                                -moz-transition: 0.5s;
                                -ms-transition: 0.5s;
                                -o-transition: 0.5s;
                                transform: scale(1.25);
                                -webkit-transform: scale(1.25);
                                -moz-transform: scale(1.25);
                                -ms-transform: scale(1.25);
                                -o-transform: scale(1.25);
                            }
                        }
                    }
                }
            }
            .lock {
                .InputToggleSwitch {
                    .switch {
                        .slider.disabled {
                            opacity: 0.25;
                            user-select: none;
                            pointer-events: none;
                        }
                    }
                }
            }
            .edit {
                display: flex;
                transform: scale(1.5);
                -webkit-transform: scale(1.5);
                -moz-transform: scale(1.5);
                -ms-transform: scale(1.5);
                -o-transform: scale(1.5);
                cursor: pointer;
                &.inactive {
                    opacity: 0.25;
                    cursor: default;
                }
                &:not(.inactive):hover {
                    svg {
                        transition: 0.5s;
                        -webkit-transition: 0.5s;
                        -moz-transition: 0.5s;
                        -ms-transition: 0.5s;
                        -o-transition: 0.5s;
                        transform: scale(1.2);
                        -webkit-transform: scale(1.2);
                        -moz-transform: scale(1.2);
                        -ms-transform: scale(1.2);
                        -o-transform: scale(1.2);
                    }
                }
            }
        }
    }
}
