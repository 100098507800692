@import "../../module.variables.scss";
.InputWraper {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    >.label {
        color: $dark;
        transition: 0.2s ease;
        font-size: 16px;
        margin-bottom: 8px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &.required {
        >.label {
            span::after {
                content: '*';
                color: $danger;
            }
        }
    }
    >.description {
        font-size: 0.9em;
        line-height: 1.4em;
        color: rgba($color: $dark, $alpha: 0.5);
        margin-bottom: 10px;
    }
    >.wraper {
        >.input {
            // border: 1px solid rgba(70, 128, 255, 0.5);
            width: 100%;
            border-radius: $borderRadius;
            position: relative;
            display: flex;
            >input {
                border: 1px solid $input-border-color;
                height: $inputHeight;
                background: $input-background-color;
                // border: none;
                outline: none;
                border-radius: $borderRadius;
                font-size: 1em;
                font-weight: 400;
                padding: 0px 10px;
                width: 100%;
                flex: 1;
                &:-webkit-autofill {
                    font-size: 1em;
                }
            }
            >textarea {
                border: 1px solid $input-border-color;
                background: $input-background-color;
                outline: none;
                border-radius: $borderRadius;
                font-size: 1em;
                font-weight: 400;
                padding: 10px;
                width: 100%;
                flex: 1;
                min-height: $inputHeight * 2;
            }
        }
        >.errorMessage {
            color: $danger;
            font-size: 0.75em;
            font-weight: 500;
            margin-top: 4px;
        }
    }
    &.mutilLocale {
        >.wraper {
            margin-bottom: 7px;
            .input {
                display: flex;
                min-height: 38px;
                .localeLabel {
                    font-size: 0.9em;
                    background: rgba($color: $dark, $alpha: 0.05);
                    border-right: 1px solid $borderColor;
                    color: $grey;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: max-content;
                    padding: 0 7px;
                    text-transform: uppercase;
                }
            }
        }
        >.generalErrorMessage {
            color: $danger;
            font-size: 0.75em;
            font-weight: 500;
        }
    }
    &.error {
        >.wraper {
            >.input {
                border: 1px solid $danger !important;
            }
        }
        >.label {
            color: $danger;
        }
    }
    &:focus-within {
        // border-color: $primary;
        >.label {
            color: $primary;
        }
        >.wraper {
            >.input {
                input {
                    border-color: #4680ff !important;
                    transition: 0.5s;
                    -webkit-transition: 0.5s;
                    -moz-transition: 0.5s;
                    -ms-transition: 0.5s;
                    -o-transition: 0.5s;
                }
            }
            >.generalErrorMessage,
            >.errorMessage {
                display: none;
            }
        }
    }
    &.disabled {
        >.wraper {
            .input {
                border-color: rgba($color: #000000, $alpha: 0);
                user-select: none;
                position: relative;
                &:after {
                    cursor: default;
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    // background: rgba($color: #000000, $alpha: 0.05);
                    border-radius: $borderRadius;
                }
                .label {
                    background: none;
                }
                input {
                    color: $dark;
                }
                // ============================ Related style ================================
                .InputSelect__single-value--is-disabled,
                .InputSelectAsync__single-value--is-disabled {
                    color: $dark;
                }
                .react-datetime-picker__clear-button,
                .react-datetime-picker__calendar-button,
                .InputSelect__indicators,
                .InputSelectAsync__indicators {
                    display: none;
                }
                .InputToggleSwitch {
                    .switch {
                        opacity: 0.25;
                        user-select: none;
                    }
                }
                // ============================ End Related style ============================
            }
        }
    }
}