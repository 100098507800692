@import '../../module.variables.scss';
.page-game-orders-list {
    .Table {
        .Table__Body {
            .email {
                color: #4680FF;
            }
            .status {
                &.status--win {
                    color: #56ca00;
                    background: rgba(86, 202, 0, 0.05);
                }
                &.status--lose {
                    color: #ff4c51;
                    background: rgba(255, 76, 81, 0.05);
                }
                &.status--draw {
                    color: #fbc217;
                    background: rgba(255, 157, 0, 0.05);
                }
                &.status--cancel {
                    color: rgba(#fff, 0.25)
                }
            }
        }
    }
}