.config {
  &--title {
    font-size: 1.5em;
    display: block;
    font-weight: lighter;
  }
  &--item {
    box-shadow: 0 0 10px 8px #13203c;
    border-radius: 8px;
    padding: 24px 12px 20px 12px;
  }
}

.Table {
  .Table__Body {
    .coin {
      display: flex;
      align-items: center;
      .coin-label {
        font-weight: 700;
      }
      .coin-avatar {
        width: 24px;
        height: 24px;
        margin-right: 16px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
      }
    }
    .email {
      color: #4680ff;
    }
  }
}
