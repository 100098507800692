.VendingMachineListByAgency {
    .back {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.25s;
        &:hover {
            filter: brightness(0.75);
        }
        .back__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
            svg {
                width: 20px;
                height: 20px;
                * {
                    fill: #fff;
                }
            }
        }
        .back__text {
            color: #fff;
        }
    }
    .button-container {
        display: flex;
        justify-content: space-between;
        .infor-container {
            margin-bottom: 15px;
            display: flex;
            .total-orders {
                margin-right: 32px;
                display: flex;
                align-items: center;
                .total-orders__icon {
                    border-radius: 50%;
                    background: rgba(#199248, 0.25);
                    width: 28px;
                    height: 28px;
                    margin-right: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media (min-width: 1200px) {
                        width: 36px;
                        height: 36px;
                    }
                    svg {
                        * {
                            fill: #199248;
                        }
                    }
                }
                .total-orders__content {
                    color: #D0D0D0;
                    font-size: 22px;
                    display: flex;
                    @media (min-width: 1200px) {
                        font-size: 28px;
                    }
                    .sub-text {
                        white-space: nowrap;
                        color: #199248;
                        font-size: 13px;
                        transform: translateY(5px);
                        -webkit-transform: translateY(5px);
                        -moz-transform: translateY(5px);
                        -ms-transform: translateY(5px);
                        -o-transform: translateY(5px);
                        @media (min-width: 1200px) {
                            font-size: 15px;
                        }
                    }
                }
            }
            .total-user {
                margin-right: 32px;
                display: flex;
                align-items: center;
                .total-user__icon {
                    border-radius: 50%;
                    background: rgba(#ff4c51, 0.25);
                    width: 28px;
                    height: 28px;
                    margin-right: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transform: rotate(180deg);
                    svg {
                        * {
                            fill: #ff4c51;
                        }
                    }
                    @media (min-width: 1200px) {
                        width: 36px;
                        height: 36px;
                    }
                }
                .total-user__content {
                    color: #D0D0D0;
                    font-size: 22px;
                    display: flex;
                    @media (min-width: 1200px) {
                        font-size: 28px;
                    }
                    .sub-text {
                        white-space: nowrap;
                        color: #ff4c51;
                        font-size: 13px;
                        @media (min-width: 1200px) {
                            font-size: 15px;
                        }
                        transform: translateY(5px);
                        -webkit-transform: translateY(5px);
                        -moz-transform: translateY(5px);
                        -ms-transform: translateY(5px);
                        -o-transform: translateY(5px);
                    }
                }
            }
            .total-volume {
                display: flex;
                align-items: center;
                .total-volume__icon {
                    border-radius: 50%;
                    background: rgba(#f3ba2f, 0.25);
                    width: 28px;
                    height: 28px;
                    margin-right: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media (min-width: 1200px) {
                        width: 36px;
                        height: 36px;
                    }
                }
                .total-volume__content {
                    color: #D0D0D0;
                    font-size: 22px;
                    display: flex;
                    @media (min-width: 1200px) {
                        font-size: 28px;
                    }
                    .sub-text {
                        white-space: nowrap;
                        color: #f3ba2f;
                        font-size: 13px;
                        @media (min-width: 1200px) {
                            font-size: 15px;
                        }
                        transform: translateY(5px);
                        -webkit-transform: translateY(5px);
                        -moz-transform: translateY(5px);
                        -ms-transform: translateY(5px);
                        -o-transform: translateY(5px);
                    }
                }
            }
        }
    }
    .Table {
        .Table__Body {
            tr {
                &:first-child {
                    td {
                        color: #04cdf9 !important;
                        background: rgba(4, 205, 249, 0.1) !important;
                    }
                }
            }
            .transaction-type {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 4px 8px;
                border-radius: 24px;
                background: rgba(221, 221, 221, 0.15);
                border: 1px solid rgba(221, 221, 221, 0.25);
                &.transaction-type--transfer {
                    background: rgba(#199248, 0.2);
                    color: #199248;
                    border: 1px solid rgba(#199248, 0.25);
                }
                &.transaction-type--withdraw {
                    background: rgba(#ff4c51, 0.2);
                    color: #ff4c51;
                    border: 1px solid rgba(#ff4c51, 0.25);
                }
                &.transaction-type--purchase {
                    background: rgba(#f3ba2f, 0.2);
                    color: #f3ba2f;
                    border: 1px solid rgba(#f3ba2f, 0.25);
                }
            }
            .action {
                .action__detail {
                    cursor: pointer;
                }
            }
        }
    }
}