// @import "../../../../module.variables.scss";
.my-startup-add-page {
  .button-container {
    display: flex;
    justify-content: end;
    .Button.success {
      svg {
        * {
          fill: #fff;
        }
      }
    }
  }
  .editor {
    width: 100%;
    .label {
      color: #8b8a8d;
      margin-bottom: 5px;
    }
    .ck-editor {
      .ck-editor__top {
        .ck-sticky-panel {
          .ck-sticky-panel__content {
            .ck-toolbar_grouping {
              border-top-left-radius: 7px;
              border-top-right-radius: 7px;
            }
          }
        }
      }
      .ck-editor__main {
        .ck-editor__editable {
          min-height: 150px;
          border-bottom-left-radius: 7px;
          border-bottom-right-radius: 7px;
        }
      }
    }
  }
  .image__items {
    position: relative;
    display: flex;
    align-items: end;
    margin-bottom: 20px;
    border: 2px solid transparent;
    div.image__items__delete-indicator {
      position: absolute;
      top: 32px;
      right: 0;
      background: #dc3545;
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.25s;
      &:hover {
          filter: brightness(0.85);
      }
      svg {
          width: 14px;
          height: 14px;
          * {
              fill: rgba(255, 255, 255, 0.5);
          }
      }
    }
    img {
      border-radius: 8px;
      height: 150px;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
  #licenseFrontUrl, #licenseBackUrl {
    .wraper {
      .input {
        .InputImage {
          height: 320px;
        }
      }
    }
  }
  .Table {
    .Table__Body {
      .startup-name {
        display: flex;
        align-items: center;
        .startup-name__icon {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.1);
          margin-right: 8px;
          transition: 0.25s;
          &:not(.invalid) {
            cursor: pointer;
            &:hover {
              transform: scale(1.1);
            }
          }
          .startup-name__icon__img {
            width: 48px;
            height: 48px;
            object-fit: cover;
            object-position: center center;
            border-radius: 50%;
            transition: 0.25s;
          }
        }
      }
      .startup-type {
        padding: 4px 8px;
        border-radius: 24px;
        background: rgba(221, 221, 221, 0.15);
        border: 1px solid rgba(221, 221, 221, 0.25);
        &.startup-type--agency {
          background: rgba(#9568ff, 0.2);
          color: #9568ff;
          border: 1px solid rgba(#9568ff, 0.25);
        }
        &.startup-type--startup {
          background: rgba(#eb62d0, 0.2);
          color: #eb62d0;
          border: 1px solid rgba(#eb62d0, 0.25);
        }
      }
      .review {
        label {
          svg {
            * {
              fill: #fbc217;
            }
          }
        }
      }
      .approval-status {
        padding: 4px 8px;
        border-radius: 24px;
        background: rgba(221, 221, 221, 1);
        border: 1px solid rgba(221, 221, 221, 1);
        color: #fff;
        &.approval-status--just-created {
          background: rgba(#1eba62, 1);
          color: #fff;
          border: 1px solid rgba(#1eba62, 1);
        }
        &.approval-status--approved {
          background: rgba(#1eba62, 1);
          color: #fff;
          border: 1px solid rgba(#1eba62, 1);
        }
        &.approval-status--cancelled {
          background: rgba(#fd5353, 1);
          color: #fff;
          border: 1px solid rgba(#fd5353, 1);
        }
        &.approval-status--pending {
          background: rgba(#fbc217, 1);
          color: #fff;
          border: 1px solid rgba(#fbc217, 1);
        }
      }
      .startup-status {
        padding: 4px 8px;
        border-radius: 24px;
        background: rgba(221, 221, 221, 0.15);
        border: 1px solid rgba(221, 221, 221, 0.25);
        &.startup-status--activated {
          background: rgba(#1eba62, 0.2);
          color: #1eba62;
          border: 1px solid rgba(#1eba62, 0.25);
        }
        &.startup-status--deactivated {
          background: rgba(#fd5353, 0.2);
          color: #fd5353;
          border: 1px solid rgba(#fd5353, 0.25);
        }
      }
      .action {
        .action__edit {
          cursor: pointer;
          transition: 0.25s;
          &:hover {
            filter: brightness(0.75);
          }
          svg {
            width: 16px;
            height: 16px;
            * {
              fill: #0d6efd;
            }
          }
        }
      }
    }
  }
  .InputWraper {
    &.disabled {
      .wraper {
        .input {
          border: 2px solid transparent;
          opacity: 0.5;
        }
      }
    }
    .label {
      // color: $sub-text-color;
    }
    .wraper {
      .tag-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex: 1 1;
        border: 1px solid rgba(70, 128, 255, 0.5);
        background: #13213e;
        outline: none;
        min-height: 38px;
        border-radius: 5px;
        font-size: 1em;
        font-weight: 400;
        padding: 10px;
        width: 100%;
        gap: 10px;
        .tag-item {
          border-radius: 20px;
          padding: 2px 12px;
          color: white;
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      .input {
        // border: 2px solid rgb(255 231 160);
        border: 2px solid transparent;
        input {
          // background: #36373d;
          color: #fff;
          // background: #fffae9;
        }
        .InputSelect {
          .InputSelect__control {
            background: #36373d;
            &.InputSelect__control--is-disabled {
              opacity: 0.8;
            }
            .InputSelect__value-container {
              .InputSelect__single-value {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
