.GrantLimitHistoryList {
    .button-container {
        display: flex;
        justify-content: space-between;
        .infor-container {
            margin-bottom: 15px;
            display: flex;
            .total-orders {
                margin-right: 32px;
                display: flex;
                align-items: center;
                .total-orders__icon {
                    border-radius: 50%;
                    background: rgba(#9568ff, 0.25);
                    width: 28px;
                    height: 28px;
                    margin-right: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media (min-width: 1200px) {
                        width: 36px;
                        height: 36px;
                    }
                    svg {
                        * {
                            fill: #9568ff;
                        }
                    }
                }
                .total-orders__content {
                    color: #D0D0D0;
                    font-size: 22px;
                    display: flex;
                    @media (min-width: 1200px) {
                        font-size: 28px;
                    }
                    .sub-text {
                        white-space: nowrap;
                        color: #9568ff;
                        font-size: 13px;
                        transform: translateY(5px);
                        -webkit-transform: translateY(5px);
                        -moz-transform: translateY(5px);
                        -ms-transform: translateY(5px);
                        -o-transform: translateY(5px);
                        @media (min-width: 1200px) {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
    .Table {
        .Table__Body {
            .action {
                .action__detail {
                    cursor: pointer;
                }
                .action__grant {
                    cursor: pointer;
                }
            }
        }
    }
    .grant-balance-popup {
        #grant-confirm-popup {
            .box {
                max-width: 480px;
            }
        }
    }
}