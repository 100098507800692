@import "../../../module.variables.scss";

.OrderList {
    .Item:has(.TableFilterInputText) {
        display: flex;
    }
    .date {
        width: max-content;
    }
    .orderCode,
    .address {
        min-width: 10rem;
    }
    .product-name {
        width: max-content;
    }
    .status {
        width: max-content;
        &.cancelled {
            color: $danger;
        }
        &.completed {
            color: $success;
        }
        &.waiting {
            color: $primary;
        }
    }
    .OrderList__Filter {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .group-item {
            display: flex;
            background: rgba(143, 155, 179, 0.2);
            padding: 5px 8px;
            margin: 0 1rem 1rem 0;
            min-height: 80px;
            gap: 12px;
            border-radius: 8px;
        }
        .group1 {
            flex-wrap: wrap;
            flex: 3;
        }
        .group2 {
            .item {
                width: 100%;
            }
            flex: 1;
            display: flex;
            align-items: center;
            input {
                width: 100%;
                margin-top: 13px;
                min-height: 38px;
                background-color: $input-background-color;
                border: 1px solid $input-border-color;
                color: white;
                padding: 2px 8px;
            }
        }
        .btn-search {
        }
    }
    td,
    th {
        text-align: left !important;
    }
}
