@import "../../../module.variables.scss";
.affiliated-agent-edit-page {
  .affiliated-agent-edit-page__content {
    background: #0f192f;
    border: 1px solid rgba(70, 128, 255, 0.25);
    border-radius: 5px;
    padding: 32px 48px;
    .title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 32px;
      color: #fff;
    }
    .form {
      .sub-title {
        margin-bottom: 16px;
        color: #fff;
      }
      .image__items {
        position: relative;
        display: flex;
        align-items: end;
        margin-bottom: 20px;
        border: 2px solid transparent;
        .image__items__delete-indicator {
          position: absolute;
          top: 32px;
          right: 0;
          background: #dc3545;
          padding: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          cursor: pointer;
          transition: 0.25s;
          &:hover {
              filter: brightness(0.85);
          }
          svg {
              width: 14px;
              height: 14px;
              * {
                  fill: rgba(255, 255, 255, 0.5);
              }
          }
        }
        img {
          border-radius: 8px;
          height: 150px;
          width: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }
      .input-container {
        #thumnail {
          .input {
            transform: scale(0.75);
            -webkit-transform: scale(0.75);
            -moz-transform: scale(0.75);
            -ms-transform: scale(0.75);
            -o-transform: scale(0.75);
            transform-origin: bottom left;
          }
        }
        #startStartupDate {
          .wraper {
            .input {
              border: 1px solid rgba(70, 128, 255, 0.5);
              background: #13213e;
            }
          }
        }
      }
      .editor {
        width: 100%;
        .label {
          color: #8b8a8d;
          margin-bottom: 5px;
        }
        .ck-editor {
          .ck-editor__top {
            .ck-sticky-panel {
              .ck-sticky-panel__content {
                .ck-toolbar_grouping {
                  border-top-left-radius: 7px;
                  border-top-right-radius: 7px;
                }
              }
            }
          }
          .ck-editor__main {
            .ck-editor__editable {
              min-height: 150px;
              border-bottom-left-radius: 7px;
              border-bottom-right-radius: 7px;
            }
          }
        }
      }
      #licenseFrontUrl, #licenseBackUrl {
        .wraper {
          .input {
            .InputImage {
              height: 320px;
              img {
                max-height: initial;
              }
            }
          }
        }
      }
      .Table {
        .action {
          &.inactive {
            opacity: 0.25;
          }
          &:not(.inactive) {
            .action--edit {
              cursor: pointer;
              &:hover {
                filter: drop-shadow(0 0 8px #7759de);
                -webkit-filter: drop-shadow(0 0 8px #7759de);
                svg {
                  transition: 0.5s;
                  -webkit-transition: 0.5s;
                  -moz-transition: 0.5s;
                  -ms-transition: 0.5s;
                  -o-transition: 0.5s;
                  transform: scale(1.25);
                  -webkit-transform: scale(1.25);
                  -moz-transform: scale(1.25);
                  -ms-transform: scale(1.25);
                  -o-transform: scale(1.25);
                }
              }
            }
            .action--delete {
              cursor: pointer;
              &:hover {
                filter: drop-shadow(0 0 8px #0466c8);
                -webkit-filter: drop-shadow(0 0 8px #0466c8);
                svg {
                  transition: 0.5s;
                  -webkit-transition: 0.5s;
                  -moz-transition: 0.5s;
                  -ms-transition: 0.5s;
                  -o-transition: 0.5s;
                  transform: scale(1.25);
                  -webkit-transform: scale(1.25);
                  -moz-transform: scale(1.25);
                  -ms-transform: scale(1.25);
                  -o-transform: scale(1.25);
                }
              }
            }
          }
        }
      }
    }
  }
  .affiliated-agent-edit-page__cover {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
