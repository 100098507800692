@import "../../module.variables.scss";

.Box {
  > * {
    position: relative;
  }

  &.mobileTable {
    .Table {
      thead {
        display: none;
      }

      // td {
      //   padding-left: 0;
      //   padding-right: 0;
      // }
    }
  }

  &.normal {
    background: $boxBgColor;
    border-radius: $borderRadius;

    > .head {
      .title {
        color: #fff;
        font-size: 1.8em;
        padding: 20px;
        // font-weight: 600;
        padding-bottom: 0;
        text-transform: capitalize;
      }
    }

    > .content {
      padding: 20px;
    }
  }

  &.title-outside {
    > .head {
      .title {
        color: #fff;
        font-size: 1.2em;
        padding: 0 0 10px 0;
        text-transform: capitalize;
      }
    }

    > .content {
      background: $boxBgColor;
      border-radius: $borderRadius;
    }
  }
}
