@import "../../../module.variables.scss";

.vending-machine-config-page {
    .InputWraper {
        .input {
            background: $input-background-color;
            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 8px;
                cursor: pointer;
                z-index: 1;
            }
        }
        &.disabled {
            .wraper {
                .input {
                    opacity: 1;
                    input {
                        border: none;
                    }
                }
            }
        }
    }
    .general-popup-compnt {
        .popup-wraper {
            width: max-content;
            max-width: initial;
        }
    }
}