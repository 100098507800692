@import "./product-detail";
@import "./order-detail-new/";
@import "./components";

.ProductList {
  .Table__FilterBox {
    .TableFilterInputText {
      input {
        min-width: 260px;
      }
    }
  }
}
