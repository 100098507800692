@import '../../../module.variables.scss';

.WithdrawProgress {
    .TableFilterInputSelect {
        min-width: 200px;
    }
    .Table {
        .Table__Body {
            .email {
                color: #4680FF;
            }
            .status {
                font-size: 14px;
                padding: 5px 8px;
                border-radius: 24px;
                &--just_created {
                    color: #fbc217;
                    background: rgba(255, 157, 0, 0.15);
                    border: 1px solid rgba(255, 157, 0, 0.25);
                    // border: 1px solid #fbc217;
                }
                &--success {
                    color: #56ca00;
                    background: rgba(86, 202, 0, 0.15);
                    border: 1px solid rgba(86, 202, 0, 0.25);
                    // border: 1px solid #56ca00;
                }
                &--rejected {
                    color: #ff4c51;
                    background: rgba(255, 76, 81, 0.15);
                    border: 1px solid rgba(255, 76, 81, 0.25);
                    // border: 1px solid #ff4c51;
                }
                &--processing {
                    color: #0466c8;
                    background: rgba(4, 102, 200, 0.15);
                    border: 1px solid rgba(4, 102, 200, 0.25);
                    // border: 1px solid #ff4c51;
                }
            }
        }
    }
}