@import "../../module.variables.scss";

.Dividend {
  table {
    tbody {
      .status {
        padding: 5px 8px;
        border-radius: 24px;
        -webkit-border-radius: 24px;
        -moz-border-radius: 24px;
        -ms-border-radius: 24px;
        -o-border-radius: 24px;
        &.status--just_created {
          color: #4680ff;
          background: rgba(70, 128, 255, 0.25);
        }
        &.status--completed {
          color: #11c15b;
          background: rgba(17, 193, 91, 0.25);
        }
        &.status--processing {
          color: #fbc217;
          background: rgba(17, 193, 91, 0.25);
        }
      }
    }
  }
}
