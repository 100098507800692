@import "../../../module.variables.scss";
.PageFranchiseTable {
  .Table {
    .Table__Body {
      tr {
        td {
          .user {
            // &:hover {
            //     text-decoration: underline;
            // }
            .user-clickable {
              cursor: pointer;
              color: #16b1ff;
              text-decoration: underline;
            }
          }
          .profit-volume-green {
            background: rgba(86, 202, 0, 0.05);
            color: #56ca00;
          }
          .profit-volume-red {
            background: rgba(255, 76, 81, 0.05);
            color: #ff4c51;
          }
          .level {
            .level__img {
              max-width: 80px;
              width: 100%;
            }
            .extend-rank {
              .extend-rank__primary {
                display: flex;
                align-items: center;
                margin-bottom: 6px;
                .extend-rank__primary__img {
                  width: 24px;
                  height: 24px;
                  margin-right: 8px;
                }
                .extend-rank__primary__label {
                  font-size: 14px;
                  font-weight: 700;
                  &.extend-rank__primary__label--gold {
                    color: #fe9836;
                  }
                  &.extend-rank__primary__label--diamond {
                    color: #77b4f3;
                  }
                }
              }
              .extend-rank__secondary {
                display: flex;
                align-items: center;
                .extend-rank__secondary__icon {
                  width: 24px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 8px;
                  svg * {
                    fill: rgba(170, 170, 170, 0.2);
                  }
                  &.extend-rank__secondary__icon--forever {
                    svg * {
                      fill: rgba(170, 170, 170, 1);
                    }
                  }
                }
                .extend-rank__secondary__label {
                  font-size: 12px;
                }
                &.extend-rank__secondary--gold {
                  .extend-rank__secondary__icon {
                    svg * {
                      fill: rgba(#fe9836, 0.75);
                    }
                  }
                  .extend-rank__secondary__label {
                    color: #fe9836;
                  }
                }
                &.extend-rank__secondary--diamond {
                  .extend-rank__secondary__icon {
                    svg * {
                      fill: rgba(#77b4f3, 0.75);
                    }
                  }
                  .extend-rank__secondary__label {
                    color: #77b4f3;
                  }
                }
              }
            }
          }
        }
      }

      .action {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        .action__plus {
          cursor: pointer;
          transition: 0.25s ease-out;
          -webkit-transition: 0.25s ease-out;
          -moz-transition: 0.25s ease-out;
          -ms-transition: 0.25s ease-out;
          -o-transition: 0.25s ease-out;
          svg {
            width: 20px;
            height: 20px;
            path {
              fill: #adb7be;
            }
          }
          &:hover {
            transform: scale(1.2);
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
            -o-transform: scale(1.2);
          }
        }
        .action__minus {
          cursor: pointer;
          transition: 0.25s ease-out;
          -webkit-transition: 0.25s ease-out;
          -moz-transition: 0.25s ease-out;
          -ms-transition: 0.25s ease-out;
          -o-transition: 0.25s ease-out;
          svg {
            width: 20px;
            height: 20px;
            path {
              fill: #adb7be;
            }
          }
          &:hover {
            transform: scale(1.2);
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
            -o-transform: scale(1.2);
          }
        }
      }
    }
  }
}
