@import "../../module.variables.scss";
.staking-page {
    .button-container {
        display: flex;
        justify-content: space-between;

        .infor-container {
            margin-bottom: 15px;
            display: flex;
            .total-amount {
                display: flex;
                align-items: center;
                .total-amount__icon {
                    border-radius: 50%;
                    background: rgba($primary, 0.25);
                    width: 28px;
                    height: 28px;
                    margin-right: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media (min-width: 1200px) {
                        width: 36px;
                        height: 36px;
                    }
                }
                .total-amount__content {
                    color: #d0d0d0;
                    font-size: 22px;
                    display: flex;
                    align-items: center;
                    @media (min-width: 1200px) {
                        font-size: 28px;
                    }
                    .sub-text {
                        white-space: nowrap;
                        color: $primary;
                        font-size: 13px;
                        transform: translateY(5px);
                        -webkit-transform: translateY(5px);
                        -moz-transform: translateY(5px);
                        -ms-transform: translateY(5px);
                        -o-transform: translateY(5px);
                        @media (min-width: 1200px) {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
    .Table {
        .Table__Body {
            .coin {
                display: flex;
                align-items: center;
                .coin-label {
                    font-weight: 700;
                }
                .coin-avatar {
                    width: 24px;
                    height: 24px;
                    margin-right: 16px;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                }
            }
            .email {
                color: #4680ff;
            }
        }
    }
}
