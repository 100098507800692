@import "../../../module.variables.scss";
.TableFilterInputText {
    display: flex;
    align-items: center;
    border-radius: $borderRadius;
    border: 1px solid $input-border-color;
    background: $input-background-color;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    &:focus-within {
        border-color: #4680ff;
    }
    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        svg {
            height: 15px;
            * {
                fill: $grey;
            }
        }
    }
    input {
        padding: 10px 15px;
        outline: none;
        // border: 1px solid $input-border-color;
        border: none;
        font-size: 1em;
        color: $white;
        width: 100%;
        max-width: 100%;
        background: $input-background-color;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }
    .btnClear {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        cursor: pointer;
        opacity: 0.7;
        padding: 5px 10px;
        svg {
            height: 10px;
            * {
                fill: $grey;
            }
        }
        &:hover {
            opacity: 1;
        }
    }
}
