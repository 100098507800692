@import "../../../module.variables.scss";

.action {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  .action__edit {
    cursor: pointer;
    transition: 0.25s ease-out;
    -webkit-transition: 0.25s ease-out;
    -moz-transition: 0.25s ease-out;
    -ms-transition: 0.25s ease-out;
    -o-transition: 0.25s ease-out;
    &:hover {
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
    }
  }
}

.Edit {
  min-width: 400px;
  .group {
    display: flex;
    justify-content: space-between;
  }
  .item {
    margin-bottom: 18px;
    .label {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
  }
  // .btnBan {
  //     background-color: #d99e0b;
  //     border: 1px solid #d99e0b;
  // }
}
