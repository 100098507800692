@import "../../../module.variables.scss";
.order-detail-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &.mobile {
        flex-direction: column-reverse;
    }
    .title {
        font-weight: bold;
    }
    .row-information:not(:first-child) {
        margin-top: 0.5rem;
    }
    .wrapper {
        background-color: $main-background-color;
        padding: 1rem;
        border-radius: 15px;
        width: 100%;
    }
    .COMPLETED {
        color: $success;
    }
    .DELIVERY_WAITING {
        color: $primary;
    }
    .CANCELLED {
        color: $danger;
    }
    .left {
        flex: 4;

        .row-1 {
            display: flex;
            gap: 1rem;
            .information-receiver {
                flex: 1;
                // background-color: $main-background-color;
                // border-radius: 15px;
                // padding: 1rem;
            }
            .information-order {
                flex: 1;
                // background-color: $main-background-color;
                // border-radius: 15px;
                // padding: 1rem;
            }
        }
        .row-2 {
            // background-color: $main-background-color;
            // padding: 1rem;
            // border-radius: 15px;
            margin-top: 2rem;
            .itemProduction {
                display: flex;
                justify-content: space-between;
                padding: 1rem 0;
                border-bottom: 1px solid white;
                .production-left {
                    display: flex;
                    // flex-direction: column;
                    gap: 1.25rem;
                    .information-production {
                        .product-name {
                            margin-bottom: 2rem;
                        }
                        .product-provided {
                            span {
                                color: $info;
                            }
                        }
                        p {
                            width: max-content;
                        }
                    }
                    // .img-product {
                    //     img {
                    //         // max-width: 50rem;
                    //         height: 8rem;
                    //     }
                    // }
                    .img-wrapper {
                        height: 80px;
                        width: 100px;
                        margin-right: 1rem;
                        background-size: cover !important;
                        background-position: center !important;
                        background-repeat: no-repeat !important;
                    }
                }
                .production-right {
                    p {
                        text-align: right;
                    }
                }
            }
        }
        .row-3 {
            width: 100%;
            // background-color: $main-background-color;
            // padding: 1rem;
            // border-radius: 15px;
            margin-top: 2rem;
            .row-3-group {
                display: flex;
                justify-content: flex-end;
                .title {
                    min-width: 15rem;
                    text-align: left;
                }
                .price-unit {
                    display: flex;
                    min-width: 12rem;
                    .price {
                        flex: 2;
                        text-align: right;
                    }
                    .unit {
                        flex: 1;
                        text-align: right;
                    }
                }
            }
            .total {
                margin-top: 3rem;
                .price,
                .unit {
                    font-size: 1.25rem;
                    color: red;
                }
                .unit {
                    margin-left: 0.5rem;
                }
            }
        }
    }

    .right {
        flex: 1;
        box-sizing: border-box;
        .status-order,
        .type-receive {
            padding: 1rem 2.5rem;
            .title {
                margin-bottom: 0.5rem;
            }
            ul {
                li {
                    list-style-type: decimal;
                }
            }
        }
    }
}
