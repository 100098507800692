// ============================ Required Core style ================================
$primary: #fbc217;
$success: #56ca00;
$info: #04cdf9;
$warning: #fbc217;
$danger: #ff4c51;

$white: #fff;
$light: #f7f9fc;
$dark: #1a2138;
$offDark: #222b45;
$grey: #8f9bb3;

// Layout
$borderRadius: 5px;
$borderColor: rgba(#8f9bb3, 0.3);
$boxShadow: 0px 2px 3px
  rgba(
    $color: #000000,
    $alpha: 0.2,
  );

$inputHeight: 38px;
$buttonHeight: 38px;

$zIndexListSuggests: 102;
$zIndexTableLoadingData: 103;
$main-background-color: #0f192f;
$secondary-background-color: #060b14;
$input-border-color: rgba(70, 128, 255, 0.5);
// $input-background-color: rgba(24, 41, 77, 0.5);
$input-background-color: #13213e;
$input-disabled-border-color: rgba(70, 128, 255, 0.1);
$input-disabled-text-color: rgba(255, 255, 255, 0.2);

// ============================ Project variable style ================================
$boxBgColor: rgba(
  $color: #0f192f,
  $alpha: 1,
);
// ============================ End Project variable style ============================


// Mixin Styles
@mixin borderBox {
  border: 1px solid $grey;
}

@mixin btnStyle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-weight: 400;
  outline: none;
  height: $buttonHeight;
  padding: 0 35px;
  border-radius: $borderRadius;
  cursor: pointer;
  outline: none;
  user-select: none;

  > svg {
    height: 16px;
    margin-right: 10px;
  }

  &:disabled {
    background: rgba($color: $grey, $alpha: 1);
    opacity: 0.2;
    cursor: not-allowed;
    color: $white;
    border-color: $grey;

    &:hover {
      background: rgba($color: $grey, $alpha: 1);
      opacity: 0.2;
      color: $white;
      border-color: $grey;
      box-shadow: none;
    }
  }
}

@mixin btnDarkColor($color) {
  @include btnStyle;

  background: $color;
  border: 1px solid $color;
  color: $dark;
  transition: 0.25s ease;

  > svg * {
    fill: $dark;
  }

  &:hover {
    box-shadow: 0px 0px 15px $color;
  }
}

@mixin btnOutlineColor($color) {
  @include btnStyle;

  background: none;
  border: 1px solid $color;
  color: $color;
  transition: 0.25s ease;
  opacity: 1;

  > svg * {
    fill: $color;
  }

  &:hover {
    opacity: 1;
    background: $color;
    color: $white;
    box-shadow: none;

    svg * {
      fill: $white;
    }
  }

  &.loading {
    border-color: rgba($color: $color, $alpha: 0);
    cursor: not-allowed;

    .iconLoading {
      svg circle {
        stroke: $color;
      }
    }

    &:hover {
      background: transparent !important;
    }
  }
}
// ============================ End Required Core style ============================

$zIndexInputArrayData__ChildForm: 500;
